import { useState, useEffect } from "preact/hooks";

/**
 * Timer component that displays the countdown to a specific launch date.
 */
export default function Timer({launchDate}: {launchDate: string}) {
    // Add state variable to store the time remaining.
    const [time, setTime] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    });

    // Add state variable to check if the app has been launched.
    const [launched, setLaunched] = useState(false);

    useEffect(() => {
        // Update the countdown every second using setInterval.
        const intervalId = setInterval(() => {
            try {
                // Get the current time and launch time in milliseconds since January 1, 1970.
                const now = new Date().getTime();
                const launchTime = new Date(launchDate).getTime();
                const deltaTime = launchTime - now;

                // Calculate the time remaining in days, hours, minutes, and seconds.
                const newTime = {
                    days: Math.floor(deltaTime / (1000 * 60 * 60 * 24)),
                    hours: Math.floor(deltaTime / (1000 * 60 * 60) % 24),
                    minutes: Math.floor(deltaTime / (1000 * 60) % 60),
                    seconds: Math.floor(deltaTime / 1000) % 60
                };

                if (deltaTime <= 0) {
                    console.log('First version launched!');
                    // Clear the interval when the countdown reaches 0.
                    clearInterval(intervalId);
                    // Set the app to launched state when the countdown reaches 0.
                    setLaunched(true);
                    // Set the time to 0 when the countdown reaches 0.
                    setTime({
                        days: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                    });
                }

                // Update the time remaining.
                if (deltaTime > 0) {
                    // Set the app to not launched state when the countdown is still running.
                    setLaunched(false);
                    // Update the time remaining.
                    setTime(newTime);
                }
                
            } catch (error) {
                console.error('Error calculating countdown:', error);
                throw new Error('Error calculating countdown');
            }
        }, 1000);

        return () => {
            // Clear the interval when the component is unmounted.
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div class="register_timer">
            {/* Display the time remaining in days, hours, minutes, and seconds. */}
            <p>
                <span>{time.days}</span>d &nbsp;
                <span>{time.hours}</span>h &nbsp;
                <span>{time.minutes}</span>min &nbsp;
                <span>{time.seconds}</span>s &nbsp;
            </p>
            {/* Display the message based on the launched state. */}
            {launched ? (
                <p class="launched">First version launched!</p>
            ) : (
                <p class="not_launched">Before launch</p>
            )}
        </div>
    );
}