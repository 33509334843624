import { useEffect, useState } from 'preact/hooks';
import Timer from './Timer.js';
import registerBg from '../assets/images/svg/bg_outlined_magenta_gradient_logo.svg';

interface RegisterProps {
    apiUrl: string;
    corsOrigin: string;
}

/**
 * Renders a registration form component.
 * 
 * @param props - The component props.
 * @returns The rendered component.
 */
export default function Register(props: RegisterProps) {
    // Define state for errors
    const [errors, setErrors] = useState({ email: '' });

    // Define the launch date
    const launchDate = 'April 28, 2024 13:56:00';

    let form: HTMLFormElement | null;
    let button: HTMLButtonElement | null;

    /**
     * Checks if an email is valid.
     * 
     * @param email - The email to validate.
     * @returns True if the email is valid, false otherwise.
     */
    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    // Get the form and button elements
    if (typeof document !== 'undefined') {
        form = document.querySelector('.register_form form') as HTMLFormElement;
        button = form?.querySelector('.cta_button');

        // Add event listener for button click
        if (window.matchMedia('(hover: none)').matches) {
            button?.addEventListener('click', () => {
                // e.preventDefault();
    
                setTimeout(() => {
                    button?.classList.add('pressed_down');
                }, 50);

                button?.classList.remove('pressed_down');
            });
        }
    }

    /**
     * Handles the form submission.
     * 
     * @param e - The submit event.
     */
    const handleSubmit = async (e: SubmitEvent) => {
        e.preventDefault();

        // Get the email from the form data
        const email = new FormData(e.target as HTMLFormElement).get('email');

        // Set up the request options
        const init: RequestInit = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': props.corsOrigin,
            }),
            body: JSON.stringify({ email }),
        };

        if (email) {
            // Check if the email is valid
            if (!isValidEmail(email.toString())) {
                setErrors({ email: 'Email is not valid.' });
                return;
            }

            try {
                // Send the email
                const response = await fetch(`${props.apiUrl}/sendEmail`, init);

                if (response.status === 202) {
                    // Add the recipient
                    const recipientResponse = await fetch(`${props.apiUrl}/addNewRecipient`, init);

                    // Update the button text and style
                    if (button) {
                        button.textContent = '';
                        button.classList.add('success_message');
                    }

                    if (recipientResponse.status === 202) {
                        // Reset the form
                        form?.reset();
                    }
                }
            } catch (error) {
                if (error instanceof TypeError) {
                    return error.message;
                }
            }
        }
    };


    // Set up an effect to clear errors after 5 seconds
    useEffect(() => {
        if (errors.email) {
            setTimeout(() => {
                setErrors({ email: '' });
            }, 5000);
        }
    }, [errors]);

    return (
        <section class="section_wrapper register_wrapper">
            <div class="section_bg register_bg">
                <img src={registerBg.src} alt="" />
            </div>
            <div class="section_width section_gap register">
                <div class="section_heading register_heading" id="join_us">
                    <h2 class="title">
                        Join our adventure and become an early adopter of Uniphy!
                    </h2>
                    <div class="content">
                        <p>
                            We will be launching a crowdfunding campaign in mid 2024 and we need your support!
                        </p>
                        <p>
                            Join the waitlist to get a notification when the <span>first version</span> launches in april and to benefit from our early bird actions on the <span>premium version</span> of the app.
                        </p>
                    </div>
                </div>

                <Timer launchDate={launchDate} />

                <div class="register_form">
                    <form method="POST" onSubmit={handleSubmit}>
                        <label for="email">
                            <p class="content">
                                Enter your email to get all the updates.
                            </p>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="email"
                                    placeholder="name@example.com"
                                    aria-label="email"
                                    required
                                />
                                <div className="input_border"></div>
                                {errors.email && <p className="email_error">{errors.email}</p>}
                            </div>
                        </label>
                        <button class="cta_button cta_register_button" type="submit">
                            Join us
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};
